.newDesign .p-0-2 {
  padding: 0% 3%;
}

.newDesign .headline {
  font-family: "Century Gothic", sans-serif;
  font-weight: 400;
  font-size: 21pt;
}

.newDesign .subheadline {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 12pt;
}

.newDesign .list {
  padding-left: 2%;
}

.newDesign  .list > li {
  list-style: none;
}
.newDesign  .list > li > span {
  vertical-align: top;
}

.newDesign  .print-text {
  font-size: 12pt;
}

.newDesign .redDot li{
  list-style: none;
}

.newDesign .redDot {
  padding-left: 25px;
}

.newDesign .redDot li::before {
  content: "•";
  color: red;
  font-size: 40px;
  vertical-align: middle;
  line-height: 5px;
  margin-left: -18px;
  padding-right: 5px;
}

.newDesign h2 {
  font-family: "Century Gothic", sans-serif;
  font-weight: bold;
  font-size: 18pt;
}

.desktopTitle {
  font-family: "Century Gothic", sans-serif;
  font-weight: bold;
  font-size: 28pt !important;
  margin-bottom: 3%;
}

.newDesign h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 14pt;
}

.newDesign .bodyText {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11pt;
}

.newDesign .hyperlink {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11pt;
  color: #1172ba;
  text-decoration-line: underline;
}

.newDesign .buttonText {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 24pt;
}

.newDesign .specialText {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 21pt;
}

.newDesign .footerText {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12pt;
}

.newDesign .footerLink {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12pt;
  text-decoration-line: none;
}

.newDesign .black {
  color: #000000;
}

.newDesign .gray {
  color: #000000;
}

.newDesign .white {
  color: #ffffff;
}

.newDesign .blue {
  color: #1172ba;
}

.newDesign .mastercardYellow {
  color: #f69e1f;
}

.newDesign .mastercardYellowBg {
  background: #f69e1f;
}

.newDesign .mastercardOrange {
  color: #f26223;
}

.newDesign .mastercardOrangeBg {
  background: #f26223;
}

.newDesign .grey {
  background: #B2BAC0;
}

.newDesign .mastercardRed {
  color: #e82128;
}

.newDesign .temperatureBarYellow {
  color: #ffcc06;
}

.newDesign .temperatureBarOrange {
  color: #f16056;
}

.newDesign .temperatureBarRed {
  color: #ed2c7e;
}

.newDesign .appFormsBackground {
  background: rgb(241 241 242);
  padding: 3%;
}

.newDesign .cardImg {
  width: 45%;
  margin: 5px auto 10px auto;
  text-align: center;
}

.smallParagraph {
  line-height: normal;
  text-align: justify;
}

.steperContainer {
  margin: 0 auto 1.5em auto;
}

.continueButton {
  padding: 0.5em 0 !important;
  font-size: 1.5em !important;
  width: 100%;
}

.seeMyOffersButton {
  padding: 0.5em 0 !important;
  font-size: 1.5em !important;
}

.btnbackLastStep {
  text-decoration: underline;
  border: none;
}

.buttonGroup {
  display: flex;
  justify-content: space-around;
}

.buttonContainer {
  width: 50%;
  margin: 0 0.5em;
}

.displayFlexCenter {
  display: flex;
  justify-content: center;
}

.smallerText {
  font-size: 0.9em;
}

.monthlyIncomeText {
  margin-top: 0.5em;
}

.disclaimerContainer {
  margin-bottom: 0.8em;
}

.radioGroup {
  display: flex;
  gap: 3%;
}

.radioGroup .radio-btn {
  gap: 8px;
}

.authorizationContainer {
  display: flex;
  align-items: flex-start;
  margin: 2em 0;
}

.authorizationText, .creditProtectionText {
  margin: 0 5%;
}

.authorizationText a {
  text-decoration-line: underline;
  color: #212529;
}

.authorizationCheckbox, .creditProtectionCheckbox {
  width: 20px;
  height: 20px;
}

.prequalAuthDisclaimerText {
  font-weight: 700;
  margin-bottom: 2em;
}

.userAgreementTitle {
  background-color: black;
  color: white;
  font-size: 1.3em;
  text-align: center;
  padding: 3px 0;
}

.userAgreementText {
  padding: 5%;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-row-gap: 1em;
}

.singleCardContainer {
  display: grid;
  place-content: center;
  place-items: center;
}

.singleCardContainer input {
  height: 20px;
  width: 20px;
  margin-top: 5px;
}

.singleCardContainer label {
  text-align: center;
  font-size: 0.9em;
  margin-bottom: 0;
}

.cardImageContainer {
  display: grid;
}

.cardImage {
  width: 70%;
}

.cardName {
  white-space: nowrap;
}

.terms .title, .preApprovedTitle {
  font-size: 1.7em;
}

.preApprovedTitle {
  text-align: center;
}

.preApprovedCreditAmount {
  font-weight: 600;
  color: #ec018e; 
}

.creditProtectionTitle {
  display: flex;
  align-items: center;
}

.creditProtectionImage {
  height: 45px;
  margin-right: 3%;
}

.creditProtectionBox {
  background: white;
  padding: 10px;
  margin-top: 1em;
  border: 1px solid #d7d8d9;
  display: grid;
  grid-gap: 1em;
}

.creditProtectionActionContainer {
  display: flex;
}

.shumerBox {
  height: 12em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  overflow-y: scroll;
  margin-bottom: 1em;
  border: 2px solid #d3d4d5;
}

.shumerBoxTextMain, .shumerBoxText {
  text-align: center;
  font-size: 1.2em;
}

.shumerBoxTextMain {
  font-size: 1.5em;
}

.banner-subtitle {
  font-size: 20pt !important;
  width: 75% !important;
}

.desktopCol {
  margin-top: 10% !important;
}

.btnDesktop {
  width: 60%;
  margin-top: 5%;
}

.featureDesc {
  font-size: large;
}

.featureItem {
  margin: 5% 0;
}

.redSmallText {
  color: red;
  font-size: 12px;
}

.banner-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500 !important;
  font-size: 30pt !important;
}

.bannerNewFlow {
  padding: 10px 0 5px 10px;
  width: "100%";
  color: #fff;
  height: 300px;
}

@media only screen and (max-width: 1000px) {
  .banner-title {
    font-size: 14pt !important;
  }

.banner-subtitle {
  font-size: 14pt !important;
  /* width: 70% !important; */
}

.desktopCol {
  margin-top: 3% !important;
}

.desktopTitle {
  font-size: 18pt !important;
}

.featureDesc {
  font-size: medium;
}

.bannerNewFlow {
  height: 130px;
}

}