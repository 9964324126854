.hide-widget {
    display:none;
}

.link-account-section {
    margin-top: 30px;
}

.link-account-section img {
    width: 100%;
}

.link-account-section .form-check label {
    padding-top: 0;
}

#flinks-parent-wrapper {
    padding-left: 80px;
    padding-right: 80px;
}

#widget-wrapper {
    margin-top:50px;
    min-height: 400px;
}


.modal-dialog {
    margin-right: auto;
    margin-left: auto;
}

.modal-80w {
    width: 80% !important;
    max-width: none!important;
}

.modal-body  #applicant-disclosure{
    max-height: 65vh;
}

#flinks-terms-link {
    color: #008cba;
    cursor:pointer;
}

.processing-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
