.banner {
  width: 100%;
  background-repeat: no-repeat;
  background-position: right top;
}

.banner.d-lg-none {
  background-size: 100% 100%;
}

/* .banner.d-lg-none h1 {
  text-align: center;
  color: #fff;
  padding: 10px 20px 0 20px;
  font-size: 32px;
  margin-bottom: 0;
} */

.banner.d-lg-none div.small {
  color: #fff;
  padding: 20px;
}

.banner.d-lg-none img {
  padding: 0;
}

.banner-content {
  /* margin: 10px; */
  color: #fff;
}

.banner-container {
  background-size: 100% 100%;
}

/* .banner-content h1 {
  width: 80%;
} */

.paddingtablet{
  padding: 0% 4% !important;
}

.banner-content h3 {
  margin-top: 15px;
  font-weight: 300;
}

.banner-content.reverse {
  color: #4d4d4d;
  background-color: #eee;
  margin: 0;
  padding: 10px;
}

.banner-content.reverse h3 {
  text-align: center;
  margin: 0;
  padding-bottom: 10px;
}

.banner-content td {
  padding: 10px 10px 0 0;
}

.banner-content.reverse td {
  padding: 0 10px 0 0;
  vertical-align: middle;
  line-height: 22px;
}

.banner-content .small {
  padding-left: 0;
}

.banner-content .row {
  margin: 15px 0;
}

.card-note {
  color: #fff;
  position: absolute;
  bottom: 80px;
  left: 125px;
}

.card-image-container {
  padding-top: 100px;
  padding-left: 50px;
}

.banner-mobile {
  padding: 10px 0 5px 10px;
  color: #fff;
}

.banner-mobile-newFlow {
  padding: 10px 0 5px 10px;
  width: "100%";
  color: #fff;
  height: 350px;
}

.banner-mobile h3 {
  width: 70%;
}

.banner-mobile p {
  width: 50%;
}

.bannerTextTitle {
    color: #FFFFFF;
    /* font-size: 60px; */
    font-weight: bold;
    /* letter-spacing: 1px; */
    padding: 0;
    margin: 0px;
    /* line-height: 40px; */
    font-family: "Roboto Bold", sans-serif;
}

.bannerTextSubtitle {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: normal;
  padding: 0;
  margin: 0px;
}

.bannerTextDescription {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: normal;
  padding: 0;
  margin: 0px;
}

.artNotation {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: normal;
  padding: 0;
  margin: 0px;
}

@media only screen and (min-width: 500px) and (max-width: 719px) {
  .bannerPosition {
    background-size: auto 55%!important;
  }
}

@media only screen and (min-width: 720px) and (max-width: 950px) {
  .bannerPosition {
    background-size: auto 80%!important;
  }
}

@media only screen and (min-width: 951px) and (max-width: 1035px) {
  .bannerPosition {
    background-size: auto 110%!important;
  }
}

@media only screen and (min-width: 1036px) and (max-width: 1200px) {
  .bannerPosition {
    background-size: auto 130%!important;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1200px) {
  .bannerPosition {
    background-position: bottom right!important;
  }
}

@media only screen and (min-width: 525px) and (max-width: 800px) {
  .bannerTextTitle {
    font-size: 48px;
}

.bannerTextSubtitle {
  font-size: 24px;
}

.bannerTextDescription {
  font-size: 16px;
}

.artNotation {
  font-size: 8px;
}

}

@media only screen and (max-width: 525px) {
  .bannerTextTitle {
    font-size: 21px;
}

.bannerTextSubtitle {
  font-size: 11px;
  max-width: 150px;
}
}

@media only screen and (max-width: 1000px) {
  .card-note {
    bottom: 80px;
    left: 50px;
  }

  .card-image-container {
    padding: 20px;
  }

  .banner-content.cfc1,
  .banner-content.cfc1 h3 {
    padding-bottom: 0;
  }

  .banner-content.cfc1 h3 {
    font-weight: 700;
  }

}
