#btn-income-calculator, #btn-see-offers {
    width: 100%;
}

#btn-view-terms {
    width: 80%;
    color: #fff;
}

.add-ons-section {
    margin-bottom: 30px;
}

#add-on-security h4, #add-on-reputation h4, #add-on-truspilot h4 {
    margin-top: 20px;
    margin-bottom: 20px;
}

#add-on-security h4 {
    color: #008cba;
}

#spouse-info {
    border: 1px solid #d8e7ea;
    padding: 10px;
    border-radius: 10px;
}

    #spouse-info #spouse-suffix {
        padding: 7px !important;
    }

/* .input-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-items: flex-start;
}
    .input-container * {
        max-width: 95%;
       
    } */

@media only screen and (max-width: 992px) {
    .userform {
        padding-top: 10px;
    }

    .input-fields {
        padding: 0;
        background-color: #eee;
        border-radius: 0;
    }
}

/* Modal */
#cfc-income-modal {
    display: flex;
    width: 430px;
    height: 360px;
    background: none;
    font-size: 13px;
    padding: 40px;
    margin: 0 auto;
}

.modal-dialog {
    margin: auto;
}

#padding-bottom {
    padding-bottom: 20px;
}

#modal-text {
    font-family: inherit;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.6;
}

#no-line-height {
    line-height: 0;
}

#bold {
    font-weight: 800;
}

#modal-input-field {
    width: 100%;
    height: 40px;
    border: 2px solid gray;
    border-radius: 5px;
    margin-bottom: 20px;
}

#centered-content {
    display: flex;
    justify-content: center;
}

#btn-popup-small {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 15px;
    padding: 10px 15px;
    font-size: 20px;
    border-radius: 5px;
    padding: 17px 22px;
    font-size: 13px;
}

#btn-popup-long {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 15px;
    font-size: 20px;
    border-radius: 5px;
    padding: 17px 22px;
    font-size: 13px;
    margin-left: 6px;
}

.error-message {
    color: red;
    font-size: 12px;
}