.terms-table .left, .terms-table .right {
    display: inline-block;
    vertical-align: top;
    padding: 10px;
}

    .terms-table .right .emphasize {
        font-size: 16pt !important;
    }

.terms-table .left {
    width: 40%;
}

.terms-table .right {
    width: 60%;
}

.nowidth body{
    width: unset !important;
}
@media screen and (min-width: 800px) {
    .nowidth body{
        width: 60% !important;
    }
}


.terms-table .left .bullet {
    font-size: 8px;
    vertical-align: middle;
}

#applicant-disclosures{
    padding: 0 !important;
}

@media print {
    .toPrint {page-break-after: always;}
}

.nocscroll{
    overflow-y: visible !important;
}

#applicant-disclosures body {
    width: 100% !important;
}

.ribbon{
    background-color: black;
}

.terms-print {
    margin: 5px 20px;
    padding: 10px;
}

p {
    padding-left: 10px;
    padding-right: 10px;
}
