

body {
    font-size: 15px;
    margin: 0;
    padding: 0;
}

.row-error{
    padding-top:100px;
    padding-bottom:100px;
   
}
