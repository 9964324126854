
.close-icon {
    position: absolute;
    right: 25px;
    top: 25px;
}

.image-container {
    width: 40%;
    margin: auto;
    border-radius: 50%;
}

#abandon-modal .modal-body {
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
}

.modal-body-text-container {
    text-align: center;
}

.modal-body-text-title {
    font-size: 28px;
}

.modal-body-text-subtitle {
    color: rgb(71, 71, 71);
    font-size: 18px;
    font-family: 'Roboto';
}

.modal-body-text-subtitle-second {
    color: rgb(71, 71, 71);
    font-size: 14px;
    font-family: 'Roboto';
}

.modal-body-button {
    margin: 2px 0;
    font-size: 16px !important;
}
