.custom-accordion .accordion-header {
  margin: 5px 0px;
}

.custom-accordion .accordion-button {
  border: 0;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 15px;
  text-align: left;
}

.custom-accordion .step-number {
  border-radius: 2em;
  border: 3px solid white;
  padding: 3px 8px;
  font-size: 0.8em;
  margin-right: 5px;
}

.pendfee-container {
  margin: 1.5em 0;
}

.congratulations {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}

.congratulations h2 {
  color: #1E1E1E;
  font-family: Arial !important;
  font-size: 45px !important;
  font-style: normal;
  font-weight: 700 !important;
  /* line-height: 150%; */
  letter-spacing: -1.408px;
}

.congratulations h3 {
  font-size: 1.85em;
  font-weight: 500;
}

.card-box {
  border: 1px solid #d9d9d9;
  padding: 1em;
}

.instructions-text {
  margin: 1.5em 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1.1em;
}

.credit-limit-number {
  font-size: 2em;
  font-weight: 600;
}

.credit-limit-text {
  font-size: 1.25em;
  margin-left: 10px;
}

.result-message {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 10px;
  font-size: 1.25em;
  padding: 0 !important; /* Override Styles */
  justify-content: space-around;
}

.result-message .attribute {
  font-weight: 600;
}

.payments .accordion-item button {
  background: #f7f7f7 !important;
  color: #000000;
}

.payment-description {
  font-size: 1.05em;
  width: 95%;
  margin: 1em 0;
}

.payments .accordion-header {
  border: 2px #c5c5c5 solid;
  border-radius: 5px;
}

.payments .accordion-item button {
  font-weight: 600;
  font-family: "Roboto";
}

.option-circle {
  border-radius: 2em;
  border: 3px solid white;
  padding: 3px 8px;
  font-size: 0.8em;
  margin-right: 5px;
}

.payments input[type="radio"] {
  accent-color: #232323;
  margin-right: 5px;
}

.pay-processing-button,
.payments .accordion-item .pay-processing-button {
  margin: 0 auto;
  margin-bottom: 1.5em;
  font-size: 1.1em;
  background: rgb(239, 62, 45) !important;
  padding: 10px 25px;
  border-radius: 8px;
}

.payments .accordion-item .pay-processing-button {
  color: white;
  font-weight: 500;
  padding: 5px 25px;
}

.debit-card-box {
  background: #f5f5f5;
  padding: 20px;
  display: flex;
    flex-direction: column;
    gap: 1.25em;
}

.debit-card-box h3 {
  color: gray;
  font-weight: 400;
  font-size: 1.1em;
}

.checkingNote {
  font-style: "italic";
  font-size: 16px
}

.cvvImg {
  width: 40%;
}

.mobile-link {
  display: none;
}

.desktop-number {
  display: inline;
}

@media only screen and (max-width: 850px) {
  .mobile-link {
    display: inline;
  }

  .desktop-number {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .congratulations h2 {
    font-size: 35px;
  }

  .debitInfo {
    margin-top: 10px;
  }

  .checkingNote {
    margin-top: 10px;
    font-size: 14px
  }
 
}

@media only screen and (max-width: 850px) {
  .congratulations {
    margin-bottom: 0.5em;
  }

  .marginMD {
    margin-bottom: 0.5em;
  }
 
}

@media only screen and (max-width: 575px) and (min-width: 401px) {
  .cvvImg {
    width: 30%;
  }

  .cvvDiv {
    display: flex;
    align-items: center
  } 
}

@media only screen and (max-width: 400px) {
  .cvvImg {
    width: 40%;
  }

}

