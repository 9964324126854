#reservation-wrapper {
    margin-bottom: 50px;
}

    #reservation-wrapper .container {
        margin-top: 40px;
    }

    #reservation-wrapper .card-wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }

#rez-input {
    border-right: 1px solid #000;
}

#rez-input input[type="text"], #rez-input label, #rez-input button {
    width: 65%;
}

#rez-input input {
    display: inline;
    text-align: center;
}

#rez-input label {
    margin-top: 15px;
}

#rez-input button {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
}

#rez-links-wrapper {
    text-align:center;
    margin-top: 30px;
}

#rez-links {
    display: inline-block;
    width: 65%;
    text-align:left;
}

#rez-links ul {
    padding-left: 15px;
}

.reservation-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

}
    .reservation-container input {
        width:80%;
    }

.alert-container{
    width:90%;
    margin-top: 1em;
}

.form-control.error {
    box-shadow: 0px 0px 6px 1px red;
}


@media only screen and (max-width: 1024px) {
    #rez-input {
        border-right: none;
    }

        #rez-input input[type="text"], #rez-input label, #rez-input button, #rez-links  {
            width: 100%;
        }

    .card-wrapper {
        display: none;
    }


}

.dm-ul{
    
    list-style: none;
    padding-left:1em !important;
    margin-left:1em;
    
}

.dm-ul > i {
    font-style: normal !important;
}

.dm-ul > li::before{
    content: "\2022";
    color: black;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -2em;
}

.tooltip-inner{
    max-width: 500px !important
}

.reservation-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reservation-form .form-group {
    width: 75% ;
}

.reservation-form-description {
    width: 90%;
    text-align: center;
    font-size: 1.2em;
    margin: 0 auto;
    margin-bottom: 1.5em;
}

.reservation-submit-button-container {
    display: flex;
    justify-content: center;
}

.reservation-submit-button-container button {
    width: 50%;
}

.brand-card-container {
    display: flex;
    align-items: center;
}

.error-message-ssn {
    font-size: 0.9em;
}

.error-message-ssn-button {
    width: 100% !important;
}

.redirection-container {
    margin: 0 auto;
    flex-direction: column;
}

.redirection-container img {
    max-width: 50%;
}

.redirection-brand-name {
    font-size: 2em;
    margin: 10px auto;
}

.redirection-text {
    font-size: 1.1em;
}

@media only screen and (max-width: 1024px) {
    .redirection-container img {
        max-width: 80%;
    }
}