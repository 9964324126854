.credit-protection {
    padding-bottom: 20px;
}

.credit-protection-img {
    width: 100%;
    text-align: center;
    padding-top: 15px;
    vertical-align: top;
}

.credit-protection-content {
    background-color: #e5e5e5;
    display: inline-block;
    padding: 10px;
}

    .credit-protection-content ul {
        margin: 10px 0;
        padding-left: 20px;
    }


@media only screen and (max-width: 992px) {
    .credit-protection-img, .credit-protection-content {
        display: block;
        width: 100%;
    }
}
