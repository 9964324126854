
#header-text-container {
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    background: #005EA2;
    margin: 0 0 10px 0;
}

#offer-container {
    margin-bottom: 100px;
    margin-top: 15px;
}

#offer-header-text {
    font-size: 24pt;
    font-weight: 300;
    text-transform: none;
    line-height: 1;
    margin-top: 10px;
}

#centered-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#offer-row {
    width: 100%;
    display: flex;
    justify-content: center;
}

#offer-row-left {
    width: 100%;
    display: flex;
    justify-content: left;
    margin-top: 25px;
    margin-bottom: 5px;
}

#offer-wrapper img {
    width: 390px;
}

#reflex-card-text {
    width: 370px;
    text-align: center;
    padding: 0;
    margin: 10px 0 60px 0;
    font-size: 1.3rem;
}

#offer-wrapper ul {
    text-align: left;
}

#offer-wrapper li {
    font-size: 17px;
    font-weight: bold;
}

#offer-form {
    background-color: #EEEEEE;
    width: 80%;
    text-align: left;
    border-radius: 10px;
    padding: 15px;
}

#input-field {
    background-color: #FFFFFF;
    width: 100%;
    height: calc(2.25rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #495057;
    padding: .375rem .75rem;
    font-size: 1rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#input-field:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

#btn {
    background-color: #00A651;
    width: 200px;
    font-size: 1rem;
    border-radius: 0;
    margin-bottom: 15px;
}

/* .input-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-items: flex-start;
}
    .input-container * {
        max-width: 95%;
       
    } */

    .error-message {
        color: red;
        font-size: 12px;
    }


@media (max-width: 480px) {
    #offer-header-text {
        font-size: 20pt;
        font-weight: 300;
        text-transform: none;
        line-height: 1;
        margin-top: 10px;
    }

    #reflex-card-text {
        margin: 10px 0;
        font-size: 16px;
    }

    #offer-row-left {
        margin-top: 10px;
    }

}

@media (max-width: 391px) {
    #offer-header-text {
        font-size: 15pt;
        font-weight: 300;
        text-transform: none;
        line-height: 1;
        margin-top: 10px;
    }

    #offer-wrapper img {
        width: 350px;
    }

    #offer-container {
        margin-bottom: 50px;
    }

    #reflex-card-text {
        margin: 10px 0;
        font-size: 16px;
    }

    #offer-row-left {
        margin-top: 10px;
    }

}