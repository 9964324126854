.card-image-product-container {
  display: flex;
  justify-content: center;
}

.card-image {
  width: 75%;
}

.card-image-revel {
  width: 50%;
}

.offer-header-text {
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 1.5em;
}

#header-text-container {
  padding: 1.5em 0;
  margin: 0;
}

.product-text div {
  font-size: 1.4em;
  font-weight: 300;
}

.gray-background {
  background: #f0f1f1;
}

.reservation-form {
  background: white;
  padding: 2em 3%;
  border-radius: 3px;
}

.limit-block {
  display: grid;
  grid-gap: 0.5em;
}

.limit-title {
  color: #7B1D5B;
  text-align: center;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 38px;
}

.limit-text {
  font-size: 1.2em;
}

.secure-title {
  font-size: 1.65em;
  font-weight: 800;
}

.secure-text-highlight {
  font-weight: 800;
  font-size: 1.1em;
}

.secure-block {
    display: grid;
    grid-gap: 0.5em;
}

.descriptions-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.descriptions-container img {
  width: 100%;
}

.add-authorized-user-text {
  color: #084b8a;
  font-size: 1.5em;
  display: flex;
  gap: 11px;
  cursor: pointer;
  margin-bottom: 0.5em;
}

.confirm-btn {
  font-size: 21px;
}

@media only screen and (min-width: 1500px) {
  .card-image {
    width: 30%;
  }

  .card-image-revel {
    width: 30%;
  }

}

@media only screen and (max-width: 770px) and (min-width: 400px) {
  .limit-title {
    font-size: 28px;
  }


}

@media only screen and (max-width: 450px) {
  .limit-title {
    font-size: 24px;
  }

  .confirm-btn {
    font-size: 15px;
  }

}

