.resultpage .row {
    margin-bottom: 20px ;
}

.resultpage .no-bottom-margin {
    margin-bottom: 0px !important ;
}

.font-115{
    font-size: 115%;
}
.resultpage h1 {
    font-size: 48px;
}

.gbCard{
    border-radius: 10px !important;
    box-shadow: rgb(0 0 0 / 30%) 4px 4px;
    background-color: rgba(246,247,252,1) !important;
}

.gbBox{
    border-radius: 10px !important;
    box-shadow: rgb(0 0 0 / 30%) 4px 4px;
}

.grandBrank p {
    font-weight: 600 !important;
    font-size: 21pt;
}

.grandBrank ul {
    list-style: none;
}


.GBQuestionMark{
    top: -10px;
    position: relative;
    margin-left: 1%;
}

.gbresponse{
    color: #f56928 !important;
    border-radius: 10px;
    background-color: white;
    padding: 2%;
    border: 1px solid grey;
}

.resultpage h1, .resultpage h3, .resultpage h4 {
    color: #008cba;
}


.grandBankList{
    list-style: none;
}
.grandBankList li::before{
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -2em;
}

.grandBankList li{
    font-size: 150%;
}

.result-message {
    padding-top: 60px;
}

.result-message-declined2 {
    text-align: center;
    color: #1E1E1E;
    font-family: Arial;
    font-size: 26px;/* 40px */
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 60px */
    letter-spacing: -0.88px;
    margin-top: 1rem;
}

    .result-message.declined {
        padding-top: 40px;
    }

    .result-message.pendfee {
        padding-top: 20px;
    }

        .result-message.pendfee div {
            margin-bottom: 10px;
        }

.result-credit-limit {
    font-size: 32px !important;
}

.resultpage .reminder {
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px;
    background-color: #f7faff;
}

    .resultpage .reminder small {
        font-size: 14px;
    }

    .resultpage .reminder li {
        margin-bottom: 10px;
    }

    .sublist li{
        margin-bottom: 0 !important;
    }

.mtop-20 {
    margin-top: 20px;
}

.remove-margin {
    margin: 0;
}

.decline-reason {
    color:#005dab;
    font-size: 21px;
}

.check-email {
    color: #1E1E1E;
    font-family: Arial;
    font-size: 18px;/* 24px */
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 36px */
    letter-spacing: -0.528px;
}

.good-news {
    color: #000;
    text-align: center;
    font-family: Arial;
    font-size: 30px; /* 34px */
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 51px */
    letter-spacing: -0.748px;
}

.optional-offers {
    color: #000;
    text-align: center;
    font-family: Arial;
    font-size: 28px;/* 34px */
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 51px */
    letter-spacing: -0.748px;
}

.otherOptions {
    color: #000;
    text-align: center;
    font-family: Arial;
    font-size: 16px;/* 20px */
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.44px;
}

@media screen and (min-width: 800px) {
    .clipboard-icon{
        float : right;
        width : 60%;
    }

    .check-icon{
        float : right;
        width : 50%;
    }
}

@media only screen and (max-width: 450px) {
    .result-message-declined2 {
        font-size: 25px;
    }

    .good-news {
        font-size: 26px; 
    }

    .optional-offers {
        font-size: 27px;
    }
   
  }
  