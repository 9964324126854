
.footer-container {
    background-color: #aaa;
    color: #fff;
    padding: 20px 0;
}

.footer-container p {
    font-size: 12pt;
}

.footer-links-reflex {
    color: #fff !important;
}

.footer-links {
    color: #fff !important;
}

.footer-links a:hover, .footer-links-reflex a:hover {
    color: #ffd800;
    cursor: pointer;
    text-decoration: underline;
};

.footer-links span {
    padding: 0 5px;
}

#cli-footer-company-section {
    margin-top: 15px;
    border-top: 1px dotted #666;
}

.footer-hours {
    margin-bottom: 1em;
}
.footer-hours p {
    font-size: 10pt;
    margin-bottom: 0;
    margin-top: 0;
}