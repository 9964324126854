.progress-container {
    width: 300px;
    margin: auto;
}

.progress-bar {
    height: 4px;
    background-color: #add8e6 !important;
    width: 100%;
    overflow: hidden;
}

.progress-bar-value {
    width: 100%;
    height: 100%;
    background-color: rgb(5, 114, 206);
    animation: indeterminateAnimation 1s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
    0% {
        transform: translateX(0) scaleX(0);
    }

    40% {
        transform: translateX(0) scaleX(0.4);
    }

    100% {
        transform: translateX(100%) scaleX(0.5);
    }
}

.progress-processing {
    text-align: center;
}

.progress-wrapper {
    position: fixed;
    text-align: center;
    top: 40%;
    z-index: 9999;
    width: 100%;
}

.progress-message {
    height: 100px;
    width: 100% !important;
    position: fixed;
    text-align: center;
    top: 50%;
    z-index: 9999;
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (min-width: 1025px) {
    .progress-message {
        height: 100px;
        width: 50% !important;
        position: fixed;
        text-align: center;
        top: 50%;
        left: 50%;
        margin-left: -25%;
    }
}