

.goButton{
    width: 30%;
}

@media only screen and (max-width: 820px) {
    .goButton{
        width: 70%;
    }
}

.GBQuestionMark{
    cursor: pointer;
}

.reminder {
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;
    background-color: #f7faff;
}

.reminder small {
        font-size: 14px;
    }

 .reminder li {
        margin-bottom: 10px;
    }