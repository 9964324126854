.terms-acknowledgements
{
    margin-left: 15px;
    margin-right: 15px;
}
.terms-acknowledgements-box
{
    padding: 15px;
    background-color: #efefef;
}
.terms-acknowledgements-title
{
    color: rgb(0, 102, 153);
}