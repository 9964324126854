.cookie-consent-banner {
    padding: 0px 5%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2147483645;
    box-sizing: border-box;
    width: 100%;
  
    background-color: white;
  }
  
  .cookie-consent-banner__inner {     
    max-width: 960px;
    margin: 0 auto;
    padding: 32px 0;
  }
  
  .cookie-consent-banner__copy { 
    margin-bottom: 16px;
  }
  
  .cookie-consent-banner__actions {    
  }
  
  .cookie-consent-banner__header {
    margin-bottom: 8px;
    
    font-family: "CeraPRO-Bold", sans-serif, arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  
  .cookie-consent-banner__description {
    font-family: "CeraPRO-Regular", sans-serif, arial;
    font-weight: normal;
    color: #838F93;
    font-size: 16px;
    line-height: 24px;
  }
  
  .cookie-consent-banner__cta {
    box-sizing: border-box;
    display: inline-block;
    min-width: 164px;
    padding: 11px 13px;
      
    border-radius: 2px;
    
    background-color: #2CE080;
     
    color: #FFF;
    text-decoration: none;
    text-align: center;
    font-family: "CeraPRO-Regular", sans-serif, arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
  
  .cookie-consent-banner__cta--secondary { 
    padding: 9px 13px;
    
    border: 2px solid #3A4649;
    
    background-color: black;
    
    color: white;
  }
  
  @media only screen and (max-width: 749px) {
    .marginMobileCookieConsent{
        margin-bottom: 5%;
        width: 100%
    }
    .noleftonmobile{
      margin-left: 0 !important;;
    }
  }

  
  
 
  
  .cookie-consent-banner__cta:last-child {
    margin-left: 16px;
  }